//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-725:_2232,_2080,_5400,_7332,_4435,_4568,_1988,_8644;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-725')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-725', "_2232,_2080,_5400,_7332,_4435,_4568,_1988,_8644");
        }
      }catch (ex) {
        console.error(ex);
      }