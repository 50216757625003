//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-725:_7564,_904,_5740,_7512,_8604,_8124,_5745,_520;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-725')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-725', "_7564,_904,_5740,_7512,_8604,_8124,_5745,_520");
        }
      }catch (ex) {
        console.error(ex);
      }