
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function dialogRT () {
    return _createElement('div', { 'className': 'dialog-container' }, _createElement('div', {
        'className': 'close',
        'onClick': this.close
    }, _createElement('svg', {
        'className': 'cm_icon cm_icon-times',
        'height': '20px',
        'role': 'img',
        'viewBox': '0 0 22 22'
    }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))), _createElement('div', { 'className': 'dialog-content' }, _createElement('div', { 'className': 'dialog-body' }, [this.DialogVehicleWidget_tabs(function () {
            function repeatTab1(tab, tabIndex) {
                return _createElement('div', {
                    'className': 'cm_tab-title ' + (this.tab === tab.id ? 'active' : ''),
                    'key': tab.id,
                    'onClick': this.onSelectTab(tab.id)
                }, '\n      Search by\n      ', _createElement('span', {}, tab.title));
            }
            return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__dialog cm_DialogVehicleWidget_tabs' }, _createElement('div', { 'className': 'cm_vehicle-tabs' }, _createElement.apply(this, [
                'div',
                { 'className': 'cm_vehicle-tabs_header' },
                _map(this.tabs, repeatTab1.bind(this))
            ]), _createElement('div', { 'className': 'cm_vehicle-tabs_content' }, [this.vehicleWidget(function () {
                    function repeatSelects1(selects, selectsIndex) {
                        return [selects(function () {
                                function repeatEntry1(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                                    return _createElement('option', {
                                        'disabled': this.loading,
                                        'value': entry.term,
                                        'key': entry.value
                                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                }
                                function repeatEntry2(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                                    return _createElement('option', {
                                        'disabled': this.loading,
                                        'value': entry.term,
                                        'key': entry.value
                                    }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                                }
                                function scopePopularEntriesUnpopularEntriesIsPopularField3() {
                                    var popularEntries = this.entries.filter(entry => entry.payload === 'Popular');
                                    var unpopularEntries = this.entries.filter(entry => entry.payload !== 'Popular');
                                    var isPopularField = popularEntries.length && unpopularEntries.length;
                                    return [
                                        !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                            'value': '',
                                            'key': 'null-option'
                                        }, '\n          Select ', this.title, '\n        ') : null,
                                        this.loading ? _createElement('option', {
                                            'key': 'loading-option',
                                            'disabled': true
                                        }, '...loading...') : null,
                                        isPopularField ? [
                                            _createElement('option', {
                                                'className': 'cm_option_title',
                                                'disabled': true,
                                                'key': '10611'
                                            }, 'Popular ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values'),
                                            _map(popularEntries, repeatEntry1.bind(this, popularEntries, unpopularEntries, isPopularField)),
                                            _createElement('option', {
                                                'className': 'cm_option_title',
                                                'disabled': true,
                                                'key': '10614'
                                            }, 'Additional ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values')
                                        ] : null,
                                        _map(isPopularField ? unpopularEntries : this.entries, repeatEntry2.bind(this, popularEntries, unpopularEntries, isPopularField))
                                    ];
                                }
                                function scopeShowSelectedValues4() {
                                    var showSelectedValues = this.selectedEntries.length;
                                    return _createElement('option', {
                                        'key': '_current',
                                        'value': '_current'
                                    }, showSelectedValues ? [this.selectedEntries.map(e => e.value).join(', ')] : null, !showSelectedValues ? [
                                        'Select ',
                                        this.title
                                    ] : null);
                                }
                                function repeatEntry5(entry, index) {
                                    return _createElement('div', {
                                        'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                        'key': entry.value,
                                        'onClick': () => this.onChange(entry.term)
                                    }, this.showCheckboxes ? _createElement('input', {
                                        'type': 'checkbox',
                                        'readOnly': true,
                                        'checked': entry.selected,
                                        'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                        'key': '3399'
                                    }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                        'className': 'cm_dropdown_hitCount',
                                        'key': '3601'
                                    }, entry.hitCount) : null);
                                }
                                return _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                                    'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                                    'key': '39'
                                }, _createElement('select', {
                                    'className': 'cm_select_inner-select cm_select__pretty',
                                    'onChange': this.onChange,
                                    'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                    'disabled': this.disabled,
                                    'aria-label': this.title
                                }, this.useNativeDropdown ? scopePopularEntriesUnpopularEntriesIsPopularField3.apply(this, []) : null, !this.useNativeDropdown ? scopeShowSelectedValues4.apply(this, []) : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                            return _createElement('div', { 'className': 'cm_filterInput' });
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                    'div',
                                    {
                                        'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                        'aria-label': this.title,
                                        'key': '2702'
                                    },
                                    !this.hideNullOption ? _createElement('div', {
                                        'className': 'option',
                                        'key': 'null-option',
                                        'onClick': () => this.onChange('')
                                    }, '\n      Select ', this.title, '\n    ') : null,
                                    this.loading ? _createElement('div', {
                                        'className': 'option',
                                        'key': 'null-option',
                                        'disabled': true
                                    }, '...loading...') : null,
                                    !this.loading ? _map(this.entries, repeatEntry5.bind(this)) : null
                                ]) : null);
                            }, { count: undefined })];
                    }
                    return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget_dropdowns cm_vehicleWidget' }, _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
                        _map(this.selects, repeatSelects1.bind(this))
                    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                        'className': 'cm_vehicle-widget_button btn btn-primary',
                        'onClick': this.selectVehicle,
                        'disabled': !this.allowToSetVehicle
                    }, '\n            GO\n          '), _createElement('div', {
                        'className': 'cm_vehicle-widget_button btn btn-primary',
                        'onClick': this.discardVehicle,
                        'disabled': !this.allowToDiscardVehicle
                    }, '\n            Reset\n          '))));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })])));
        }, {
            widgetName: 'undefined',
            items: undefined
        })])));
}
        export const componentNames = ["cm:filterInput","cm:vehicleWidget","cm:DialogVehicleWidget_tabs"]