export default {
  BUTTON_PRIMARY_CLASS: 'btn btn-primary',
  BUTTON_SECONDARY_CLASS: 'btn btn-secondary',

  PRODUCT_REPEATER_CLASS: 'product_listing_main row',

  FACET_DIALOG_TITLE: 'Filter Options',
  FACET_DIALOG_BUTTON_CLASS: 'btn btn-default',
  SHOW_RESULTS_CLASS: 'btn btn-default',

  SEARCH_BOX_FORM_CLASS: 'search-form',

  VEHICLE_WIDGET_HOME_CLASS: 'container',
  SELECT_TITLE: 'Select {{ title }}',
};
