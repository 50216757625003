import { SearchPage } from 'Components'
import TemplateSearchPage from 'Stores/_default-store/Templates/SearchPage.rt'
import { Garage } from 'Components'
import TemplatefitmentSearchgarage from 'Stores/_default-store/Templates/fitmentSearch/garage.rt'
import { SearchBoxDialogButton } from 'Components'
import TemplatesearchBoxdialogButtonAdaptable from 'Stores/_default-store/Templates/searchBox/dialogButtonAdaptable.rt'
import { SearchHeader } from 'Components'
import { FacetPanel } from 'Components'
import { FilterChips } from 'Components'
import { SearchResult } from 'Components'
import { VehicleTabs } from 'Components'
import TemplatefitmentSearchvehicleTabs from 'Stores/_default-store/Templates/fitmentSearch/vehicleTabs.rt'
import { ContextDialog } from 'Components'
import TemplatefitmentSearchdialog from 'Stores/_default-store/Templates/fitmentSearch/dialog.rt'
import { SearchBoxDialog } from 'Components'
import TemplateSearchBoxDialog from 'Stores/_default-store/Templates/SearchBoxDialog.rt'

export const compMap = {
  SearchPage,
Garage,
SearchBoxDialogButton,
SearchHeader,
FacetPanel,
FilterChips,
SearchResult,
VehicleTabs,
ContextDialog,
SearchBoxDialog
} 
export const tplMap = {
  'SearchPage': TemplateSearchPage,
'fitmentSearch/garage': TemplatefitmentSearchgarage,
'searchBox/dialogButtonAdaptable': TemplatesearchBoxdialogButtonAdaptable,
'fitmentSearch/vehicleTabs': TemplatefitmentSearchvehicleTabs,
'fitmentSearch/dialog': TemplatefitmentSearchdialog,
'SearchBoxDialog': TemplateSearchBoxDialog
};