import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isolatedDialog, isolatedHeader, getKeyFromWidgetName } from 'Addons/fitmentSearch/isolatedKeys.ts';
import type { TemplateFunction, TemplateFunctionInvoker, TemplateResult } from 'Components/types.ts';
import {
  isolatedDiscardVehicle,
  setFitmentFields,
  vehicleSelected,
} from 'Core/actions/fitmentSearch/index.js';
import {
  createAllRequiredFieldsHaveSelectionSelector,
  garageDataSelector,
  selectedVehicleSelector,
} from 'Core/selectors/fitmentSearch/index.js';
import fitmentSearchConfig from 'Models/uiConfig/fitmentSearchConfig.js';
import VehicleWidget from './vehicleWidget/vehicleWidget.jsx';

import type { IsolatedKey } from 'Addons/fitmentSearch/isolatedKeys.ts';

type Tab = {
  id: string;
  title: string;
  fields: {
    base: string[];
    extra: string[];
  };
};

interface Params {
  tab: string;
  tabs: Tab[];
  onSelectTab: (tab: string) => () => void;
  vehicleWidget: TemplateFunctionInvoker<unknown>;
}

type Props = {
  template: TemplateFunction<Params>;
  name: string;
  tabs: Tab[];
  initCollapsed?: boolean;
  globalVehicleDiscardEnabled?: boolean;
  columnBreakpoint?: number;
  noStubSelect?: boolean;
  useNativeDropdown?: boolean;
};

const VehicleTabs: FunctionComponent<Props> = ({
  template,
  name,
  tabs,
  initCollapsed,
  globalVehicleDiscardEnabled,
  columnBreakpoint,
  noStubSelect,
  useNativeDropdown,
}) => {
  const dispatch = useDispatch();

  tabs.forEach((tab, idx) => {
    tab.id = `tab-${idx}`;
  });

  const { currentTab, setCurrentTab } = useTabs(tabs[0].id);

  const isolatedKey = getKeyFromWidgetName(name) as IsolatedKey;

  const isAllRequiredFieldHasSelection = useSelector(
    createAllRequiredFieldsHaveSelectionSelector(isolatedKey),
  ) as boolean;
  const selectedVehicle = useSelector(selectedVehicleSelector);
  const garageItems = useSelector(garageDataSelector);

  const getTabById = useCallback((id: string) => tabs[tabs.map((t) => t.id).indexOf(id)], [tabs]);
  const activeTabFields = getTabById(currentTab).fields;

  useEffect(() => {
    dispatch(setFitmentFields(activeTabFields, isolatedKey));
  }, [activeTabFields, dispatch, isolatedKey]);

  useEffect(() => {
    if (selectedVehicle.selection.length) {
      const tabsMap = tabs.map((tab) => ({
        ...tab,
        fields: Object.values(tab.fields).flat(),
      }));

      const tabIdByVehicle = tabsMap.filter((tab) =>
        selectedVehicle.map((v) => v.field).every((f) => tab.fields.includes(f)),
      )[0]?.id;

      setCurrentTab(getTabById(tabIdByVehicle)?.id || tabs[0].id);
    }
  }, [getTabById, selectedVehicle, setCurrentTab, tabs]);

  const discardVehicle = () => {
    dispatch(isolatedDiscardVehicle(isolatedKey, globalVehicleDiscardEnabled));
    if (isAllRequiredFieldHasSelection) {
      fitmentSearchConfig.onVehicleDiscarded();
    }
  };

  const onSelectTab = (tab: string) => {
    setCurrentTab(tab);

    const currentTabFields = Object.values(getTabById(tab).fields).flat();
    const currentTabVehicle = garageItems
      .reverse()
      .find((vehicle) => vehicle.map((v) => v.field).every((f) => currentTabFields.includes(f)));

    if (currentTabVehicle && !([isolatedDialog, isolatedHeader] as IsolatedKey[]).includes(isolatedKey)) {
      dispatch(vehicleSelected(currentTabVehicle, { isolatedKey }));
    } else {
      discardVehicle();
    }
  };

  const vehicleWidget: TemplateFunctionInvoker<unknown> = (templ) => {
    const otherProps = { isolatedKey, fields: Object.values(activeTabFields).flat() };
    const vehicleWidgetProps = {
      name: 'TabsVehicleWidget',
      template: templ,
      initCollapsed,
      isAlwaysActive: true,
      noStubSelect,
      columnBreakpoint,
      useNativeDropdown,
      ...otherProps,
    };
    return (<VehicleWidget {...vehicleWidgetProps} key="VehicleWidget" />) as TemplateResult;
  };

  const component = template.call({
    tab: currentTab,
    tabs,
    onSelectTab: (tab) => () => onSelectTab(tab),
    vehicleWidget,
  });

  return component;
};

export default VehicleTabs;

function useTabs(initTab: string) {
  const [currentTab, setCurrentTab] = useState(initTab);

  return { currentTab, setCurrentTab };
}
